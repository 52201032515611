const GLOBAL_LANG_HIUI = {
    "hi_common_cancel": "取消",
    "hi_common_confirm": "确定",
    //03472
    //03472
    "hi_query_all": '全部',
    "hi_query_noempty": "不能为空！",
    "hi_query_sysscheme": "系统方案",
    "hi_query_reset": "重置",
    "hi_query_btnname": "查询",
    "hi_query_more": "更多",
    "hi_order_defaultall": "综合",
    "hi_timescheme_month": '本月',
    "hi_timescheme_yeartonow": "今年至今",
    "hi_timescheme_last6month": "最近六个月",
    "hi_timescheme_today": "本日",
    "hi_timescheme_yesterday": "昨日",
    "hi_timescheme_last7days": "近7天",
    "hi_timescheme_last28days": "近28天",
    "hi_timescheme_last84days": "近84天",
    "hi_timescheme_startdate": "开始日期",
    "hi_timescheme_enddate": "结束日期",
    "hi_timescheme_year": "本年度",
    "hi_timescheme_week": "本周",
    "hi_timescheme_quarter": "本季度",
    "hi_timescheme_split": "到",
    "hi_timescheme_halfyear": "近半年",
    "hi_timescheme_oneyear": "近一年",
    "hi_pushdata_linkSelect": "请选择需要打开的链接！",
    "hi_pushdata_hasAuth": "当前请求功能您没有权限访问！",
    "hi_pushdata_requestError": "请求异常",
    "hi_treequery_placeholder": "输入关键字进行过滤",
    "hi_numberrange_splitstr": "到",
    "hi_datalist_nodata": "暂无数据！",
    "hi_importexcel_uploadname": "Excel上传",
    "hi_importexcel_templatename": "模板下载",
    "hi_importexcel_noallowfile": "不支持上传{0}格式!",
    "hi_importexcel_limitfilesize": "限制上传文件大小为{0}M!",
    "hi_exportexcel_exportname": "Excel导出",
    "hi_exportexcel_buildfile": "文件生成中",
    "hi_exportexcel_tip":"导出提示",
    "hi_exportexcel_isdownload": "是否取消文件下载?",
    "hi_dataRequest_request": "数据请求中",
    "hi_dataRequest_isCacel": "是否取消请求?",
    "hi_dataRequest_tip": "提示",
    "hi_dataRequest_tip": "提示",
    "hi_dataRequest_confirm": "确定",
    "hi_exportexcel_cancel": "取消",
    "hi_exportexcel_confirm": "确定",
    "hi_exportexcel_cancel": "取消",
    "hi_exportexcel_builddatacomplate": "数据生成完成！",
    "hi_expression_close": "关闭",
    "hi_expression_confirm": "关闭",
    "hi_expression_dialogname": "表达式",
    //03472

    //01521 begin

    "hi_page_confirm": "确定",
    "hi_page_cancel": "取消",
    "hi_page_prompt": "提示",
    "hi_page_cannotBeNull": "不能为空",
    "hi_page_outOfRange": "输入的字符超出",
    "hi_page_dataPromptNotSubmitted": "检测到有未提交的数据,是否还原",
    "hi_page_dataNotModified": "数据未修改",
    "hi_page_initializing": "正在初始化..",
    //01521 end

    "hi_table_file": "个文件",
    "hi_table_index": "序号",
    "hi_table_operate": "操作",
    "hi_table_add": "新增",
    "hi_table_del": "删除",
    "hi_table_addsub": "新增子节点",
    "hi_table_emptyText": "暂无数据",
    "hi_table_confirmFilter": "筛选",
    "hi_table_resetFilter": "重置",
    "hi_table_clearFilter": "全部",
    "hi_table_sumText": "合计",
    "hi_table_lockCol": "锁定列",
    "hi_table_unlockCol": "解锁列",
    "hi_table_fillUp": "向上填充",
    "hi_table_fillDown": "向下填充",
    "hi_table_copy": "复制",
    "hi_table_copyRow": "复制整行",
    "hi_table_copyCol": "复制整列",
    "hi_table_copyCell": "复制单元格",  
    "hi_table_download": "下载",
    "hi_table_downloadAll": "下载所有",
    "hi_table_downloadSelect": "选中下载",
    "hi_table_maxAddNode": "最多只能添加{0}级节点",
    "hi_table_yesterday": "昨天",
    "hi_table_today": "今天",
    "hi_table_aweek": "一周",  
    "hi_table_paste": "excel粘贴",
    "hi_table_pasteRow": "指定位整行粘贴",
    "hi_table_pasteCol": "指定位整列粘贴",
    "hi_table_pasteMsg": "navigator.clipboard 仅支持通过 HTTPS 提供的页面",
    "hi_table_colSetting": "字段配置",
    "hi_table_colsConfig": "列表字段配置",




    // 12112
    "hi_messagebox_title": "提示",
    "hi_messagebox_confirm": "确定",
    "hi_messagebox_cancel": "取消",


    "hi_toolbar_add": "添加",
    "hi_toolbar_del": "删除",
    "hi_toolbar_reset": "取消",
    "hi_toolbar_save": "保存",
    "hi_toolbar_submit": "提交",
    "hi_toolbar_callout": "调单",
    "hi_toolbar_nexttache": "流转",
    "hi_toolbar_backtache": "回退",
    "hi_toolbar_signout": "加签",
    "hi_toolbar_processSign": "处理加签",
    "hi_toolbar_turnOut": "转办",
    "hi_toolbar_terminate": "终止",
    "hi_toolbar_umpire": "反审",
    "hi_toolbar_monitor": "流程监控",
    "hi_toolbar_savesuccess": "数据保存成功",
    "hi_toolbar_processflowsuccess": "流程处理成功",
    "hi_toolbar_limitequals": "请选择相同的:",
    "hi_toolbar_limitnoequals": "请选择不同的:",
    "hi_toolbar_limitnoequals1": "相同记录已跳过！",
    "hi_toolbar_finished": "流程结束",
    "hi_toolbar_actors": "下个执行者",
    "hi_toolbar_loader": "数据处理中...",
    "hi_toolbar_agree": "同意",
    "hi_toolbar_disagree": "不同意",
    "hi_toolbar_selectOneError": "至少选择一个执行者！",
    "hi_toolbar_removeallhint": "确定要删除所有记录吗？",
    "hi_toolbar_cancelhint": "确定要取消吗？",
    "hi_toolbar_umpirehint": "确定要启用反审吗？",
    "hi_toolbar_savehint": "数据未保存，是否保存数据?",
    "hi_toolbar_circulationway": "返回方式",
    "hi_toolbar_circulationway_default": "重新流转",
    "hi_toolbar_circulationway_again": "原路返回",
    "hi_toolbar_performType": "原路返回",
    "hi_toolbar_performType1": "独占",
    "hi_toolbar_performType2": "会签",
    "hi_toolbar_performType3": "顺序",
    "hi_toolbar_signType_BeforSign": "前加签",
    "hi_toolbar_signType_AfterSign": "后加签",
    "hi_toolbar_approvecomments": "审批意见",
    "hi_toolbar_assigneeLabel": "额外办理人",
    "hi_toolbar_previousName": "上一节点",
    "hi_toolbar_processor": "处理人",
    "hi_toolbar_previousCount": "节点处理人：共",
    "hi_toolbar_previousCount2": "人审批",
    "hi_toolbar_processortime": "处理时间",
    "hi_toolbar_username": "用户名",
    "hi_toolbar_userid": "用户ID",
    "hi_toolbar_userorgname": "编制名",
    "hi_toolbar_bzid": "编制ID",
    "hi_toolbar_collapse": "收起",
    "hi_toolbar_expand": "展开",


    "hi_form_required": " 必填！",

    "hi_tree_search": "输入关键字进行过滤",
    "hi_tree_addPeerBtn": "添加同级",
    "hi_tree_add": "添加",
    "hi_tree_edit": "编辑",
    "hi_tree_del": "删除",
    "hi_tree_noNode": "节点不存在",
    "hi_tree_removehint": "请先删除子节点！",

    "hi_calendar_add": "新增日程",
    "hi_calendar_refresh": "刷新",
    "hi_calendar_month": "月",
    "hi_calendar_week": "周",
    "hi_calendar_day": "日",
    "hi_calendar_between": "到",
    "hi_calendar_prevMonth": '上个月',
    "hi_calendar_nextMonth": '下个月',
    "hi_calendar_today": '今天',
    "hi_calendar_time": '时间',


    "hi_select_placeholder": "请选择",

    "hi_selectGrid_queryname": "请输入关键词",

    "hi_dataSelect_hasselect": "当前记录已选！",
    "hi_dataSelect_selectOneError": "至少选择一条记录!",
    "hi_dataSelect_Loading": "数据加载中...",
    "hi_dataSelect_selectall": "全选",
    "hi_dataSelect_clear": "清空",
    "hi_dataSelect_selectAdd": "添加选中",
    "hi_dataSelect_selectclear": "删除选中",
    "hi_dataSelect_queryCdionsNull": "明细条件未传！",
    "hi_dataSelect_numberFieldNull": "未配置单号!",
    "hi_dataSelect_nosoucredataset": "来源数据集不存在",
    "hi_dataSelect_noin": "数据不在查找范围内！",

    "hi_upload_deleteTip": '按 delete 键可删除',
    "hi_upload_uploadName": "点击上传",
    "hi_upload_delete": '删除',
    "hi_upload_removeall": '清空',
    "hi_upload_preview" : "查看图片",
    "hi_upload_continue": '继续上传',
    "hi_upload_limit": "当前限制选择{0}个文件，本次选择了{1}个文件，共选择了{2}个文件",
    "hi_upload_fileExtension": "后缀为",
    "hi_upload_limitcount": "数量不超过{0}个",
    "hi_upload_fileSize": "大小不超过{0}M",
    "hi_upload_hint": "只能上传[ {0} ]文件",
    "hi_upload_fileExtensionLimit": "不支持上传{0}格式!",
    "hi_upload_files": "个文件",
    "hi_upload_uploadBtn": "下载",
    "hi_upload_previewbtn": "预览",
    "hi_upload_copy": "复制路径",


    "hi_imagecropper_dialogtitle": "切图",
    "hi_imagecropper_select": "选择封面",
    "hi_imagecropper_scalebig": "放大",
    "hi_imagecropper_scalesmall": "缩小",
    "hi_imagecropper_rotateLeft": "左旋转",
    "hi_imagecropper_rotateRight": "右旋转",
    "hi_imagecropper_upload": "上传封面",
    "hi_imagecropper_originalupload": "原图上传",
    "hi_imagecropper_error":"图片类型要求",

    "hi_signature_click":"点击签名",
    "hi_signature_dialogtitle":"签名",
    "hi_signature_remove":"删除签名",
    "hi_signature_reset":"清空画板",
    "hi_signature_ok":"确认签名",
    "hi_signature_nosign":"未签名！",


    "hi_mapSelect_translate": "坐标转换失败！",

    "hi_page_pageInit": "没有找到初始化接口配置,请在环境配置中新增pageInitUrl节点配置",
    "hi_page_pagecontrolstate": "页面控件状态",
    "hi_page_reload":"重新加载",
    "hi_page_close":"关闭",
    "hi_page_userConflict":"用户已切换,当前用户与登录用户不符",
    "hi_page_msgTip":"提示",

    "hi_monitor_approvalRecord": "审批记录",
    "hi_monitor_flow": "流程",
    "hi_monitor_displayname": "流程名称",
    "hi_monitor_ordernumber": "流程编号",
    "hi_monitor_createtime": "创建时间",
    "hi_monitor_finishtime": "完成时间",
    "hi_monitor_flowstate": "流程状态",
    "hi_monitor_finish": "已完成",
    "hi_monitor_taskname": "任务名称",
    "hi_monitor_noReceived": "未接收",
    "hi_monitor_timeConsuming": "耗时",
    "hi_monitor_approver": "审批人",
    "hi_monitor_processingStatus": "处理状态",
    "hi_monitor_processe": "需处理",
    "hi_monitor_postscript": "附言",
    "hi_monitor_flowchart": "流程图",
    "hi_monitor_gantt": "甘特图",
    "hi_monitor_processed": "已处理",
    "hi_monitor_back": "回退",
    "hi_monitor_sysAuto": "系统自动流转",
    "hi_monitor_terminate": "终止",
    "hi_monitor_cancel": "取消",
    "hi_monitor_transfer": "转办",
    "hi_monitor_taskWithdraw": "任务撤回",
    "hi_monitor_tsBeforSigning": "前加签中",
    "hi_monitor_tsAfterSigning": "后加签中",
    "hi_monitor_tsBeforSignDW": "前加签处理",
    "hi_monitor_tsAfterSignDW": "后加签处理",
    "hi_monitor_tsBeforSigned": "已前加签",
    "hi_monitor_tsAfterSigned": "已后加签",
    "hi_monitor_day": "天",
    "hi_monitor_hour": "小时",
    "hi_monitor_Min": "分",
    "hi_monitor_second": "秒",
    "hi_monitor_gantt_dept": "所属部门",
    "hi_monitor_gantt_role": "岗位职称",



    "hi_dbType_AuditPoint_0": "已审核",
    "hi_dbType_AuditPoint_1": "运行中",
    "hi_dbType_AuditPoint_2": "审批中",
    "hi_dbType_AuditPoint_99": "终止",
    "hi_dbType_boolean_yes": "是",
    "hi_dbType_boolean_no": "否", 

}
export default GLOBAL_LANG_HIUI